import { Controller } from "stimulus"
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  static values = { currentUser: String, dsn: String }

  get currentUser() {
    return JSON.parse(this.currentUserValue)
  }
  initialize() {
    Sentry.init({
      dsn: this.dsnValue
    })

    if (!this.currentUser) return

    Sentry.configureScope(scope => {
      scope.setUser(this.currentUser)
    })
  }
}
