import SortableAptitudeQuestionsController from './sortable_aptitude_questions_controller'
import fetchWithDefaults from '../helpers/fetchWithDefaults'

export default class SortableAptitudeQuestionGroupsController extends SortableAptitudeQuestionsController {
  static targets = ['emptyHelpText']
  static values = { updateUrl: String }

  initialize() {
    const defaultSortableOptions = this.defaultSortableOptions
    const sortableOptions = {
      ...defaultSortableOptions,
      onAdd: event => {
        if (defaultSortableOptions.onAdd) defaultSortableOptions.onAdd(event)
        this.handleQuestionsChanged(event.item)
      },
      onRemove: event => {
        if (defaultSortableOptions.onRemove) defaultSortableOptions.onRemove(event)
        this.handleQuestionsChanged(event.item)
      }
    }

    super.initialize(sortableOptions)
  }
  get $questions() {
    return $(this.element).find('.question')
  }
  get questionIds() {
    return this
      .$questions
      .map((index, element) => $(element).data('id'))
      .toArray()
  }
  get isEmpty() {
    return this.$questions.length === 0
  }
  get updateUrl() {
    return this.updateUrlValue
  }
  handleQuestionsChanged(question) {
    this.updateQuestionIds(question)
    this.showOrHideEmptyHelpText()
  }
  updateQuestionIds(question) {
    const data = {
      aptitude_question_group: {
        aptitude_question_ids: this.questionIds
      }
    }

    fetchWithDefaults(this.updateUrl, {
      method: 'PATCH',
      body: JSON.stringify(data)
    })
      .then(() => {
        $('.unpublished-changes-warning').removeClass('hidden')
      }).catch(() => {
        toastr.error("Couldn't update question group.")
      })
  }
  showOrHideEmptyHelpText() {
    if (this.isEmpty) {
      this.showEmptyHelpText()
    } else {
      this.hideEmptyHelpText()
    }
  }
  hideEmptyHelpText() {
    this.emptyHelpTextTarget.classList.add('d-none')
  }
  showEmptyHelpText() {
    this.emptyHelpTextTarget.classList.remove('d-none')
  }
}
