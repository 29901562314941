import { Controller } from 'stimulus'
import debounce from 'lodash.debounce'

const findMatches = (string, regex) => {
  const matches = []
  let result
  while ((result = regex.exec(string)) !== null) {
    const matchedText = result[0]
    const startIndex = result.index
    const endIndex = result.index + matchedText.length - 1
    matches.push({
      matchedText,
      startIndex,
      endIndex
    })
  }
  return matches
}

const getVariables = string => {
  // https://regexr.com/509c4
  const regex = /{{[^{}]*?}}/gi
  return findMatches(string, regex)
}

// This takes two bounding boxes of the same width and height
// and creates a new bounding box that covers both of them.
const joinBoundingBoxes = (leftBoundingBox, rightBoundingBox) => {
  return {
    top: leftBoundingBox.top,
    left: leftBoundingBox.left,
    height: leftBoundingBox.bottom - leftBoundingBox.top,
    width: rightBoundingBox.right - leftBoundingBox.left
  }
}

export default class EmailTemplateLinterController extends Controller {
  static values = { usableVariables: String }

  get usableVariables() {
    return this.usableVariablesValue
  }
  get warnings() {
    if (!this._warnings) {
      this._warnings = []
    }

    return this._warnings
  }
  set warnings(newWarnings) {
    this._warnings = newWarnings
  }
  createWarning(error) {
    throw new Error(`This should be implemented by a sub-class.`)
  }
  getText() {
    throw new Error(`This should be implemented by a sub-class.`)
  }
  debouncedLint() {
    if (!this.boundAndDebouncedLint) {
      const boundLint = this.lint.bind(this)
      this.boundAndDebouncedLint = debounce(boundLint, 200)
    }

    this.boundAndDebouncedLint()
  }
  toggleWarningTooltipsFromMouse(event) {
    for (const warning of this.warnings) {
      warning.debouncedToggleTooltipFromMouse(event.clientX, event.clientY)
    }
  }
  toggleWarningTooltipsFromSelection(event) {
    for (const warning of this.warnings) {
      // warning.debouncedToggleTooltipFromSelection(event.clientX, event.clientY)
    }
  }
  lint() {
    const text = this.getText()
    const errors = this.getErrors(text)
    this.clearWarnings()
    this.createWarnings(errors)
  }
  clearWarnings() {
    const disconnect = warning =>
      warning.disconnectFromDom()

    this.warnings.forEach(disconnect)
    this.warnings = []
  }
  createWarnings(errors) {
    const createWarning = error => this.createWarning(error)
    this.warnings = errors.map(createWarning)
  }
  getErrors(emailTemplate) {
    return [
      ...this.getUnusableVariableErrors(emailTemplate),
      ...this.getCurlyBraceErrors(emailTemplate)
    ]
  }
  getUnusableVariableErrors(emailTemplate) {
    const isNotusable = variable => !this.usableVariables.includes(variable.matchedText)

    const variables = getVariables(emailTemplate)
    return variables
      .filter(isNotusable)
      .map(error => {
        error.message = `${error.matchedText} isn't one of the available merge codes.`
        return error
      })
  }
  getCurlyBraceErrors(emailTemplate) {
    // https://regexr.com/509fd
    const singleCurlyBraceRegex = /[^{]{[^{}]*?}[^}]/gi
    const singleCurlyBraceErrors = findMatches(emailTemplate, singleCurlyBraceRegex)

    // https://regexr.com/509fg
    const tooManyLeftCurlyBracesRegex = /[^{]{{[^{}]*?}[^}]/gi
    const tooManyLeftCurlyBraceErrors = findMatches(emailTemplate, tooManyLeftCurlyBracesRegex)

    // https://regexr.com/509fj
    const tooManyRightCurlyBracesRegex = /[^{]{[^{}]*?}}[^}]/gi
    const tooManyRightCurlyBraceErrors = findMatches(emailTemplate, tooManyRightCurlyBracesRegex)

    const curlyBraceErrors = [
      ...singleCurlyBraceErrors,
      ...tooManyLeftCurlyBraceErrors,
      ...tooManyRightCurlyBraceErrors
    ]

    curlyBraceErrors.forEach(error => {
      error.message = `Wrap merge codes in double curly braces, {{like_this}}`
      // Discard first and last characters.
      error.matchedText = error.matchedText.slice(1, error.matchedText.length - 1)
      error.startIndex += 1
      error.endIndex -= 1
    })

    return curlyBraceErrors
  }
}
