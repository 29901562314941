import { Controller } from 'stimulus'
import $ from '../jquery'

export default class extends Controller {
  static targets = ['disableOnValidSubmit']

  disableTargetsIfValid(event) {
    if (this.formIsValid) {
      const $targets = $(this.disableOnValidSubmitTargets)
      $targets.prop('disabled', true)
    }
    return true
  }

  enableTargetsIfValid() {
    const $targets = $(this.disableOnValidSubmitTargets)
    $targets.prop('disabled', false)
  }

  get formIsValid() {
    const $form = $(this.element)
    return $form.parsley().isValid()
  }
}
