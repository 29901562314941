import ToggleBasedOnSelectValueController from "./toggle_based_on_select_value_controller"
import toSnakeCase from '../helpers/toSnakeCase.js'

export default class extends ToggleBasedOnSelectValueController {
  connect() {
    super.connect()
  }

  toggle(element, values, value) {
    if (element && values) {
      let hidden = true
      let disabled = hidden
      for (let _value of values.split(',')) {
        if (toSnakeCase(_value) === value) {
          hidden = false
          disabled = hidden
        }
      }

      element.hidden = hidden
      element.querySelectorAll('input[data-never-enable="false"]').forEach(input => {
        input.disabled = disabled
      })
    }
  }
}
