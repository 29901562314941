import ToggleHiddenController from './toggle_hidden_controller.js'

export default class extends ToggleHiddenController {
  static targets = ['toggleable']

  toggle (event) {
    if (this.wasToggled) return
    this.wasToggled = true
    return super.toggle(event)
  }
}
