import { Controller } from 'stimulus'

const setDisabledForChildInputs = (target, disabled) => {
	const childInputs = target.querySelectorAll('input')
	for (const input of childInputs) {
		input.disabled = disabled
	}
}

const disableChildInputs = target => setDisabledForChildInputs(target, true)
const enableChildInputs = target => setDisabledForChildInputs(target, false)

export default class extends Controller {
	static targets = ['selection', 'selectionsContainer', 'notifyOfDeselection']

	get nonSelectedSelections() {
		return this.selectionTargets.filter(selection => !selection.hasAttribute('data-selected'))
	}

	connect() {
		super.connect()
		// this.nonSelectedSelections.forEach(disableChildInputs)
	}

	getIdFromEvent(event) {
		return event.currentTarget.getAttribute('data-id')
	}

	getSelectionWithId(id) {
		return this.selectionTargets.find(selectionTarget => this.matchesId(selectionTarget, id))
	}

	matchesId(element, id) {
		return element.getAttribute('data-id') === id
	}

	getSelectionToUpdate(event) {
		const id = this.getIdFromEvent(event)
		return this.getSelectionWithId(id)
	}

	select(event) {
		event.preventDefault()

		const selection = this.getSelectionToUpdate(event)
		selection.setAttribute('data-selected', 'true')
		enableChildInputs(selection)

		// Move selection to the bottom of its container.
		this.selectionsContainerTarget.appendChild(selection)
	}

	getClosestSelectionTo(element) {
		const selection = `[data-${this.identifier}-target~="selection"]`
		return element.closest(selection)
	}

	deselect(event) {
		event.preventDefault()

		const selection = this.getClosestSelectionTo(event.currentTarget)
		const id = selection.getAttribute('data-id')

		selection.removeAttribute('data-selected')
		disableChildInputs(selection)

		this.notifyDeselectionTargetsWithId(id)
	}

	notifyDeselectionTargetsWithId(id) {
		this.notifyOfDeselectionTargets
			.filter(target => this.matchesId(target, id))
			.forEach(target => {
				target.dispatchEvent(new Event('option-deselected'))
			})
	}
}
