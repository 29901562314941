import debounce from 'lodash.debounce'

// This takes two bounding boxes of the same width and height
// and creates a new bounding box that covers both of them.
const joinBoundingBoxes = (leftBoundingBox, rightBoundingBox) => {
  return {
    top: leftBoundingBox.top,
    left: leftBoundingBox.left,
    height: leftBoundingBox.bottom - leftBoundingBox.top,
    width: rightBoundingBox.right - leftBoundingBox.left
  }
}

export default class {
  get firstLetterBoundingBox () {
    if (!this._firstLetterBoundingBox) {
      this._firstLetterBoundingBox = this.getBoundingBoxAtIndex(
        this.error.startIndex
      )
    }

    return this._firstLetterBoundingBox
  }
  get lastLetterBoundingBox () {
    if (!this._lastLetterBoundingBox) {
      this._lastLetterBoundingBox = this.getBoundingBoxAtIndex(
        this.error.endIndex
      )
    }

    return this._lastLetterBoundingBox
  }
  getBoundingBoxAtIndex (index) {
    throw new Error(`This method should be overridden in a sub-class.`)
  }
  get $element () {
    if (!this._$element) {
      this._$element = $(this.element)
    }
    return this._$element
  }
  constructor (error) {
    this.error = error
    this.element = document.createElement('div')

    this.setElementAttributes()
  }
  debouncedToggleTooltipFromMouse (mouseX, mouseY) {
    if (!this._debouncedToggleTooltipFromMouse) {
      const boundToggleTooltip = this.toggleTooltipFromMouse.bind(this)
      this._debouncedToggleTooltipFromMouse = debounce(boundToggleTooltip, 200)
    }

    this._debouncedToggleTooltipFromMouse(mouseX, mouseY)
  }
  toggleTooltipFromMouse (mouseX, mouseY) {
    if (this.isInElement(mouseX, mouseY)) {
      this.showTooltip()
    } else {
      this.hideTooltip()
    }
  }
  isInElement (x, y) {
    return (
      x >= this.firstLetterBoundingBox.left &&
      x <= this.lastLetterBoundingBox.right &&
      y >= this.firstLetterBoundingBox.top &&
      y <= this.firstLetterBoundingBox.bottom
    )
  }
  debouncedToggleTooltipFromSelection (selection) {
    if (!this._debouncedToggleTooltipFromSelection) {
      const boundToggleTooltip = this.toggleTooltipFromSelection.bind(this)
      this._debouncedToggleTooltipFromSelection = debounce(
        boundToggleTooltip,
        200
      )
    }

    this._debouncedToggleTooltipFromSelection(selection)
  }
  toggleTooltipFromSelection (selection) {
    if (this.isInErrorRange(selection)) {
      this.showTooltip()
    } else {
      this.hideTooltip()
    }
  }
  isInErrorRange (selection) {
    console.warn('Not implemented yet.')
    return true
  }
  showTooltip () {
    this.$element.tooltip('show')
  }
  hideTooltip () {
    this.$element.tooltip('hide')
  }
  setElementAttributes () {
    this.element.className = 'email-template-linter-warning'
    this.element.setAttribute('data-toggle', 'tooltip')
    this.element.setAttribute('title', this.error.message)
  }
  setElementPosition () {
    this.element.style.position = 'absolute'

    const firstLetterBoundingBox = this.firstLetterBoundingBox
    const lastLetterBoundingBox = this.lastLetterBoundingBox

    // This is relative to the viewport, i.e. the part of the page that
    // is currently visible in the browser.
    const joined = joinBoundingBoxes(
      firstLetterBoundingBox,
      lastLetterBoundingBox
    )

    // Absolute positioning attributes like `top` and `left` aren't relative to the
    // viewport, they're relative to the size of the whole page, including
    // parts that are currently scrolled out of the viewport.
    //
    // Since we're using absolute positioning for our warning, if we ignore the
    // part of the page that's scrolled out of the viewport, our warning will show
    // up in the wrong place.
    //
    // Fortunately, the browser gives us window.scrollX and window.scrollY and we
    // can use those to figure out how much of the page is scrolled out of the viewport.

    // Account for parts of the page that are scrolled out of the viewport vertically.
    joined.top += window.pageYOffset
    // Account for parts of the page that are scrolled out of the viewport horizontally.
    joined.left += window.pageXOffset

    this.element.style.top = `${joined.top}px`
    this.element.style.left = `${joined.left}px`
    this.element.style.height = `${joined.height}px`
    this.element.style.width = `${joined.width}px`
  }
  connectToDom () {
    this.setElementPosition()
    document.body.appendChild(this.element)
  }
  disconnectFromDom () {
    this.hideTooltip()
    this.element.remove()
  }
}
