import * as QueryParams from '../helpers/query_params'
import StimulusReflexController from './stimulus_reflex_controller'

export default class PaginationController extends StimulusReflexController {
  static values = { page: String, pageParam: String }

  get page() {
    return JSON.parse(this.pageValue)
  }

  get pageParam() {
    return this.pageParamValue || 'page'
  }

  get headerElement() {
    return document.body.querySelector('[data-stimulus="header"]');
  }

  scrollHeaderIntoView() {
    const header = this.headerElement
    header.scrollIntoView()
  }

  async setPage(event) {
    // If we haven't opened an ActionCable connection yet,
    // just bail out and let the browser's default link behavior
    // handle it. This is nice if, for example, we're having
    // problems with web sockets or they're not supported
    // by the user's browser.
    if (!this.isActionCableConnectionOpen()) {
      return
    }

    event.preventDefault()

    const page = this.page
    const pageParam = this.pageParam
    QueryParams.update({
      [pageParam]: page
    })

    await this.stimulateWhenConnected('Params#update_params', { page: page })

    this.scrollHeaderIntoView()
  }
}
