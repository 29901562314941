import Warning from './Warning'

export default class TrixWarning extends Warning {
  static createAndConnectToDom(error, trixEditor) {
    console.log('create and connect to dom')
    const warning = new TrixWarning(error, trixEditor)
    warning.connectToDom()
    return warning
  }
  constructor(error, trixEditor) {
    super(error)
    this.trixEditor = trixEditor
  }
  getBoundingBoxAtIndex(index) {
    console.log('get bounding box at index')
    return this.trixEditor.getClientRectAtPosition(index)
  }
}