// polyfills required for Stimulus:
// https://github.com/stimulusjs/stimulus/tree/master/packages/%40stimulus/polyfills
import applyElementClosestPolyfill from "element-closest";
import "mutation-observer-inner-html-shim";
import "eventlistener-polyfill";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

applyElementClosestPolyfill(window);

export const application = Application.start();

const context = require.context("controllers", true, /.js$/);
const definitions = definitionsFromContext(context);
application.load(definitions);

