import Dropzone from "dropzone"
import { Controller } from "stimulus"
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter
} from "../helpers/dragAndDrop"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

export default class extends Controller {
  static targets = ["input", "labelIfRequired", "fileRequestParagraph", "hideAfterValidations"]

  connect() {
    this.dropZone = createDropZone(this)
    this.bindEvents()

    // Some boilerplate to get around some weird console interaction with Dropzone
    Dropzone.autoDiscover = false

    // If a file was uploaded and the page reloads because validations are triggered then fire this event
    if (this.resumeWasUploaded()) {
      this.hideAfterValidationsTarget.setAttribute("data-hidden", "")
      this.makeDropzoneUnclickable()
    }
  }

  // Private

  resumeWasUploaded() {
    return this.inputTarget.dataset.resumeWasUploaded ? true : false
  }

  makeDropzoneUnclickable() {
    // We need to set pointer events to none so that the user can't upload more files after 
    // validations fire (unless they remove the previous upload and start over)
    document.getElementsByClassName("dropzone-default")[0].style.pointerEvents = "none"
  }

  completelyRemoveShowValidationsTargetAndHiddenInput() {
    // If we make this an actual stimulus target we can't remove it from the DOM without breaking our connect method since the target won't be present.
    // We need to remove it completely so the hidden input doesn't interefere with the automatically added hidden input with the correct value. 
    document.querySelector('#show_after_validations_target').parentNode.removeChild(document.querySelector('#show_after_validations_target'))
  }

  makeDropzoneClickable() {
    // If we don't set the dropbox container's pointer events back to auto the user won't be able to add a new file
    document.getElementsByClassName("dropzone-default")[0].style.pointerEvents = "auto"
  }

  removeAutomaticallyAddedStaleResumeInputs() {
    const resumeInputs = document.getElementsByName('applicant[applications_attributes][0][resume]')
    // The input which is automatically added by simple_form will always be the last input in the DOM. We want to remove it otherwise it will 
    // stick around and confuse our server when we submit the form with the newly automatically added input. 
    const automaticallyAddedInput = resumeInputs[resumeInputs.length - 1]
    automaticallyAddedInput.parentNode.removeChild(automaticallyAddedInput)
  }

  removeFileAfterValidations(event) {
    event.preventDefault()
    this.hideAfterValidationsTarget.removeAttribute("data-hidden")
    this.completelyRemoveShowValidationsTargetAndHiddenInput()
    this.makeDropzoneClickable()
    this.removeAutomaticallyAddedStaleResumeInputs()
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        // We might not need to pass the auth token here. Remove and test in staging.
        file.accepted && new DirectUploadController(file, this).start()
      }, 500)
    })

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort()
    })
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") }
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles() {
    return this.element.dataset.dropzoneMaxFiles || 1
  }

  get maxFileSize() {
    return this.element.dataset.dropzoneMaxFileSize || 256
  }

  get acceptedFiles() {
    return this.element.dataset.acceptedFiles || "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
  }

  get addRemoveLinks() {
    return this.element.dataset.addRemoveLinks || true
  }
}

class DirectUploadController {
  constructor(file, stimulusController) {
    // const progressTracker = new ProgressTracker(file, stimulusController)
    // this.directUpload = new ActiveStorage.DirectUpload(file, stimulusController.url, progressTracker)
    this.directUpload = new ActiveStorage.DirectUpload(file, stimulusController.url)
    this.stimulusController = stimulusController
    // one half of the circle
    this.file = file
  }

  start() {
    // one half of the circle
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.stimulusController.inputTarget.name
    insertAfter(input, this.stimulusController.inputTarget)
    return input
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.stimulusController.dropZone.emit("error", this.file, error)
    this.stimulusController.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.stimulusController.dropZone.emit("success", this.file)
    this.stimulusController.dropZone.emit("complete", this.file)
  }
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false
  })
}

// Not working - need to refactor from the top down soon. 

// class ProgressTracker {
//   constructor(file, stimulusController) {
//     this.file = file
//     this.stimulusController = stimulusController
//   }

//   directUploadWillStoreFileWithXHR(xhr) {
//     this.bindProgressEvent(xhr)
//     this.emitDropzoneUploading()
//   }

//   bindProgressEvent(xhr) {
//     this.xhr = xhr
//     this.xhr.upload.addEventListener("progress", event =>
//       this.uploadRequestDidProgress(event)
//     )
//   }

//   uploadRequestDidProgress(event) {
//     const element = this.stimulusController.element
//     const progress = (event.loaded / event.total) * 100
//     findElement(
//       this.file.previewTemplate,
//       ".dz-upload"
//     ).style.width = `${progress}%`
//   }

//   emitDropzoneUploading() {
//     this.file.status = Dropzone.UPLOADING
//     this.stimulusController.dropZone.emit("processing", this.file)
//   }
// }