import SelectableSearchableController from './selectable_searchable_controller'

const clamp = ({ value, min, max }) => {
  // Make sure value is above min.
  value = Math.max(value, min)

  // Make sure value is below max.
  value = Math.min(value, max)

  return value
}

export default class extends SelectableSearchableController {
  static targets = SelectableSearchableController.targets

  get matchingOptions() {
    const matchesQuery = option => option.getAttribute('data-matches-query') === 'true'
    const notSelected = option => !option.hasAttribute('data-selected')
    return this.optionTargets.filter(option => matchesQuery(option) && notSelected(option))
  }

  get highlightedOption() {
    return this.optionTargets.find(option => option.getAttribute('data-highlighted') === 'true')
  }

  get highlightIndex() {
    if (!this._highlightIndex) {
      this._highlightIndex = 0
    }

    return this._highlightIndex
  }

  set highlightIndex(i) {
    i = clamp({ value: i, min: 0, max: this.matchingOptions.length - 1 })
    this._highlightIndex = i

    const optionToHighlight = this.matchingOptions[i]
    this.highlightOption(optionToHighlight)
  }

  highlightOption(option) {
    if (this.highlightedOption) {
      this.highlightedOption.removeAttribute('data-highlighted')
    }

    if (option) {
      option.setAttribute('data-highlighted', true)
    }
  }

  handleKeydown(event) {
    // See `this.highlightClickedElementAndTriggerBlur`
    // for why we're calling queryTarget.blur here instead
    // of just selecting the highlighted option.
    const handlers = {
      ArrowUp: () => this.highlightIndex--,
      ArrowDown: () => this.highlightIndex++,
      Enter: () => this.queryTarget.blur(),
    }

    const handler = handlers[event.key]
    if (handler) {
      event.preventDefault()
      return handler()
    }

    return true
  }

  highlightClickedElementAndTriggerBlur(event) {
    // Ideally we wouldn't need this method,
    // and instead would just call the select method.
    //
    // However, something in our select method
    // triggers a blur event.
    //
    // Since the select method makes a selection
    // and our code also selects the currently
    // highlighted option on blur, the UI breaks,
    // showing a double selection.
    //
    // As a workaround, we just highlight the clicked
    // option and trigger the blur event ourselves.
    //
    // A more correct solution would be to figure out
    // what's causing the blur event when we call `select`
    // and prevent it.
    event.preventDefault()

    const id = this.getIdFromEvent(event)
    const option = this.getOptionWithId(id)

    this.highlightOption(option)

    this.queryTarget.blur()
  }

  selectHighlightedIfShowingSuggestions() {
    if (!this.highlightedOption) return
    if (!this.element.hasAttribute('data-show-suggestions-container')) return

    this.selectOptionWithId(this.highlightedOption.getAttribute('data-id'))
  }

  setQuery(event) {
    super.setQuery(event)
    this.highlightIndex = 0
  }
}
