import TransitionHiddenController from './transition_hidden_controller'
import '../stylesheets/controllers/modal_controller.scss'
import sleep from '../helpers/sleep'

export default class extends TransitionHiddenController {
  static targets = ['wrapper', 'body', 'background']

  async show(event) {
    event.preventDefault()
    await this.showElement(this.wrapperTarget)
    await sleep(50)
    this.showElement(this.bodyTarget)
    this.showElement(this.backgroundTarget)

    // Stop the body from scrolling while modal is open
    document.body.classList.add('overflow-hidden')
  }

  async hide() {
    const hideBackgroundPromise = this.hideElement(this.backgroundTarget)
    const hideBodyPromise = this.hideElement(this.bodyTarget)
    await Promise.all([hideBackgroundPromise, hideBodyPromise])
    await this.hideElement(this.wrapperTarget)
    await sleep(200)

    // Allow the page to scroll again once closed
    document.body.classList.remove('overflow-hidden')
  }

  async hideWithoutSubmit(event) {
    event.preventDefault()
    await this.hide(event)
  }

  async submitAndHide(event) {
    event.preventDefault()
    // Submit here
    await this.hide()
  }

  get toggleableTargets() {
    return [this.wrapperTarget, this.bodyTarget, this.backgroundTarget]
  }

}

