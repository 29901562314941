import ToggleTargetHiddenController from './toggle_target_hidden_controller'

export default class extends ToggleTargetHiddenController {
  static targets = ['requiredCampaignTestContainer', 'requiredCampaignTestCheckbox']

  hasMultipleRequiredTests() {
    const requiredCampaignTests = this.requiredCampaignTestCheckboxTargets.filter(target => target.checked)
    return requiredCampaignTests.length > 1
  }

  toggleContainers() {
    const hasMultipleRequiredTests = this.hasMultipleRequiredTests()
    if (hasMultipleRequiredTests) {
      this.requiredCampaignTestContainerTargets.forEach(target => super.showElement(target))
    } else {
      this.requiredCampaignTestContainerTargets.forEach(target => super.hideElement(target))
    }
  }
  
  toggle (event) {
    this.toggleContainers()
    super.toggle(event)
  }
}
