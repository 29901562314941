import awaitEventOrTimeout from '../helpers/awaitEventOrTimeout'
import ToggleHiddenController from './toggle_hidden_controller'
import sleep from '../helpers/sleep'
import '../stylesheets/controllers/toggle_hidden_controller.scss'

export default class extends ToggleHiddenController {
  static targets = ['toggleable']

  getNextTransitionStyle(transitionStyle) {
    if (transitionStyle === 'leave') {
      return 'enter'
    } else {
      return 'leave'
    }
  }

  async transition(transitionStyle, element) {
    await sleep(99)

    if (!element.hasAttribute('data-transition-type')) {
      return
    }

    const nextTransitionStyle = this.getNextTransitionStyle(transitionStyle)

    element.setAttribute('data-transition-state', `${transitionStyle} to`)

    await awaitEventOrTimeout(element, 'transitionend')

    element.setAttribute('data-transition-state', `${nextTransitionStyle} from`)
  }

  async beforeHide(element) {
    await this.transition('leave', element)
  }

  async afterShow(element) {
    await this.transition('enter', element)
  }
}
