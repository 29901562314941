import { Controller } from 'stimulus'

const last = collection => collection[collection.length - 1]
const withoutLastCharacter = string => string.slice(0, string.length - 1)

const getCurrentPathWithoutTrailingSlash = () => {
  const href = window.location.href
  const lastCharacter = last(href)

  if (lastCharacter === '/') {
    return withoutLastCharacter(href)
  } else {
    return href
  }

  return hrefWithoutTrailingSlash
}

class Response {
  static fromInput(input) {
    const adjective = input.getAttribute('data-adjective')
    const rating = input.value
    return new Response({ adjective, rating })
  }
  get input() {
    const adjectiveSelector = `[data-adjective="${this.adjective}"]`
    const ratingSelector = `[value="${this.rating}"]`
    return document.querySelector(adjectiveSelector + ratingSelector)
  }
  constructor({ adjective, rating }) {
    this.adjective = adjective
    this.rating = rating
  }
}

class ResponseSet {
  constructor(ratingsByAdjective) {
    this.ratingsByAdjective = ratingsByAdjective
  }
  get adjectives() {
    return Object.keys(this.ratingsByAdjective)
  }
  getResponseForAdjective(adjective) {
    const rating = this.ratingsByAdjective[adjective]
    return new Response({ adjective, rating })
  }
  add({ adjective, rating }) {
    this.ratingsByAdjective[adjective] = rating
  }
  forEach(callback) {
    this.adjectives.forEach(adjective => {
      const response = this.getResponseForAdjective(adjective)
      callback(response)
    })
  }
}

class SaveableResponseSet extends ResponseSet {
  static get supportsLocalStorage() {
    var test = 'supportsLocalStorage'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (e) {
      return false
    }
  }
  static get localStoragePath() {
    return getCurrentPathWithoutTrailingSlash() + '/personality-profile-responses'
  }
  static load() {
    if (!SaveableResponseSet.supportsLocalStorage) return new SaveableResponseSet({})

    const path = SaveableResponseSet.localStoragePath
    console.log(path)
    const unparsed = window.localStorage.getItem(path)
    if (unparsed) {
      const ratingsByAdjective = JSON.parse(unparsed)
      return new SaveableResponseSet(ratingsByAdjective)
    } else {
      return new SaveableResponseSet({})
    }
  }
  saveResponse(response) {
    this.add(response)
    this.save()
  }
  save() {
    if (!SaveableResponseSet.supportsLocalStorage) return

    const path = SaveableResponseSet.localStoragePath
    const stringified = this.stringify()
    window.localStorage.setItem(path, stringified)
  }
  stringify() {
    return JSON.stringify(this.ratingsByAdjective)
  }
  fillOutForm() {
    this.forEach(response => {
      response.input.checked = true
    })
  }
}

export default class extends Controller {
  connect() {
    if (!SaveableResponseSet.supportsLocalStorage) {
      console.log("This browser doesn't support localStorage, so we won't save your responses to your device.")
      return
    }

    this.savedResponseSet = SaveableResponseSet.load()
    this.savedResponseSet.fillOutForm()
  }

  handleRatingChange(event) {
    const response = Response.fromInput(event.target)
    this.savedResponseSet.saveResponse(response)
  }
}
