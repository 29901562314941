import ValidatedFormController from '../validated_form_controller'
import serializeForm from '../../helpers/serializeForm'
import fetchWithDefaults from '../../helpers/fetchWithDefaults'
import toastr from '../../toastr'
import * as Sentry from '@sentry/browser'

export default class extends ValidatedFormController {
  static targets = ValidatedFormController.targets
  get formAction () {
    return this.element.getAttribute('action')
  }
  handleSubmit (event) {
    event.preventDefault()

    if (!this.formIsValid) {
      return
    }

    this.disableTargetsIfValid()
    this.submit()
      .then(response => {
        //if (!response.ok) {
          //const error = new Error(`Error when submitting the personality profile.`)
          //error.networkResponse = response
          //throw error
        //}

        document.location.reload()
      })
      .catch(err => {
        this.catchSubmitErrors(err)
      })
  }
  submit () {
    //return fetchWithDefaults(this.formAction, {
      //method: 'POST',
      //headers: {
        //'Content-Type': 'application/x-www-form-urlencoded'
      //},
      //body: serializeForm(this.element)
    //})
    const serializedForm = serializeForm(this.element)
    return new Promise((resolve, reject) => {
      $.ajax(this.formAction, {
        method: 'POST',
        success: resolve,
        error (jqXHR, textStatus, errorString) {
          const error = new Error(`Couldn't submit personality profile.`)
          error.textStatus = textStatus
          error.errorString = errorString
          reject(error)
        },
        data: serializedForm
      })
    })
  }
  catchSubmitErrors (error) {
    this.enableTargetsIfValid()
    toastr.warning("Couldn't submit the test. Check your connection?")
    Sentry.captureException(error)
  }
}
