export default async function isOnline() {
  if (navigator && !navigator.onLine) {
    return false
  }

  const url = 'https://hiresuccess.com/'
  // const url = 'https://icanhazip.com/'

  try {
    await window.fetch(url, {
      mode: 'no-cors'
    })
    return true
  } catch (_) {
    return false
  }
}