import { Controller } from 'stimulus'
import FlashMessageController from './flash_message_controller'

export default class extends FlashMessageController {
  get successMessage () {
    return this.element.getAttribute('data-success-message') || 'Success!'
  }

  get href () {
    return this.element.getAttribute('href')
  }

  postToHref (event) {
    event.preventDefault()
    $.ajax(this.href, {
      type: 'POST',
      // success: () => toastr.success(this.successMessage)
      success: window.confirm("Login link sent.")
    })
  }
}
