import awaitEvent, { AwaitEventTimedOutError } from './awaitEvent'

export default async function awaitEventOrTimeout(...args) {
  try {
    const result = await awaitEvent(...args)
    return result
  } catch (error) {
    if (error instanceof AwaitEventTimedOutError) {
      console.warn(error)
    } else {
      throw error
    }
  }
}