import StimulusReflexController from './stimulus_reflex_controller'

export default class extends StimulusReflexController {
	static targets = ["changeImage"]

	connect() {
		super.connect()
		$(this.element)
			.cloudinary_fileupload()
			.on('cloudinarydone', (event, data) => {
				this.onUploadDone(data)
			})
	}

	onUploadDone(data) {
		const cloudinaryImage = $.cloudinary.image(data.result.public_id, {
			format: data.result.format,
			version: data.result.version
		})

		$('.cloudinary-image-preview')
			.children('img')
			.attr('src', cloudinaryImage[0].src)

		this.changeImageTarget.setAttribute('value', data.result.public_id)
	}
}
