import ToggleHiddenController from './toggle_hidden_controller'

export default class extends ToggleHiddenController {
  get toggleableTargets() {
    return [this.element]
  }

  get internetExploreIsDetected() {
    return !!window.MSInputMethodContext && !!document.documentMode;
  }

  toggleInternetExplorerWarningBanner(event) {
    if (this.internetExploreIsDetected) {
      this.toggle(event)
    } else {
    }
  }

  connect(event) {
    this.toggleInternetExplorerWarningBanner(event)
  }
}
