import { Controller } from 'stimulus'
import SelectableController, { SelectableMixin } from './selectable_controller'
import SearchableController, { SearchableMixin } from './searchable_controller'

const SelectableSearchableController = SelectableMixin(SearchableMixin(Controller))

export default class extends SelectableSearchableController {
  static targets = [...SelectableController.targets, ...SearchableController.targets]

  selectOptionWithId (id) {
    super.selectOptionWithId(id)
    this.clearInputs()
    // Wait a bit to focus the query target. It won't work if we try to do it
    // synchronously here because it hasn't lost focus yet.
    setTimeout(() => this.queryTarget.focus(), 50)
  }
}
