import { Controller } from 'stimulus'

const last = collection => collection[collection.length - 1]
const withoutLastCharacter = string => string.slice(0, string.length - 1)

const getCurrentPathWithoutTrailingSlash = () => {
  const href = window.location.href
  const lastCharacter = last(href)

  if (lastCharacter === '/') {
    return withoutLastCharacter(href)
  } else {
    return href
  }

  return hrefWithoutTrailingSlash
}

class Response {
  static fromInput(input) {
    const questionId = input.getAttribute('data-q-id')
    const answerId = input.value
    return new Response({ questionId, answerId })
  }

  get input() {
    // const questionTextSelector = `[data-q-id="${this.questionId}"]`
    const answerSelector = `[value="${this.answerId}"]`
    return document.querySelector(answerSelector)
  }

  constructor({ questionId, answerId }) {
    this.questionId = questionId
    this.answerId = answerId
  }
}

class ResponseSet {
  constructor(answersByQuestionId) {
    this.answersByQuestionId = answersByQuestionId
  }
  get questionIds() {
    return Object.keys(this.answersByQuestionId)
  }
  getResponseForQuestionId(questionId) {
    const answerId = this.answersByQuestionId[questionId]
    return new Response({ questionId, answerId })
  }
  add({ questionId, answerId }) {
    this.answersByQuestionId[questionId] = answerId
  }
  forEach(callback) {
    this.questionIds.forEach(questionId => {
      const response = this.getResponseForQuestionId(questionId)
      callback(response)
    })
  }
}

class SaveableResponseSet extends ResponseSet {
  static get supportsLocalStorage() {
    var test = 'supportsLocalStorage'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (e) {
      return false
    }
  }
  static get localStoragePath() {
    return getCurrentPathWithoutTrailingSlash() + '/aptitude-test-responses'
  }
  static load() {
    if (!SaveableResponseSet.supportsLocalStorage) return new SaveableResponseSet({})

    const path = SaveableResponseSet.localStoragePath
    let unparsed = window.localStorage.getItem(path)
    if (unparsed && unparsed != "undefined") {
      const answersByQuestionId = JSON.parse(unparsed)
      return new SaveableResponseSet(answersByQuestionId)
    } else {
      return new SaveableResponseSet({})
    }
  }
  saveResponse(response) {
    this.add(response)
    this.save()
  }
  save() {
    if (!SaveableResponseSet.supportsLocalStorage) return

    const path = SaveableResponseSet.localStoragePath
    const stringified = this.stringify()
    window.localStorage.setItem(path, stringified)
  }
  stringify() {
    return JSON.stringify(this.answersByQuestionId)
  }
  fillOutForm() {
    this.forEach(response => {
      if (!response.input) {
        debugger
      }
      response.input.checked = true
    })
  }
}

export default class extends Controller {
  connect() {
    if (!SaveableResponseSet.supportsLocalStorage) {
      console.log("This browser doesn't support localStorage, so we won't save your responses to your device.")
      return
    }

    this.savedResponseSet = SaveableResponseSet.load()
    this.savedResponseSet.fillOutForm()
  }

  handleRatingChange(event) {
    const response = Response.fromInput(event.target)
    this.savedResponseSet.saveResponse(response)
  }
}
