import { Controller } from 'stimulus'

export default class extends Controller {
  get selectedOption() {
    const options = this.element.querySelectorAll('option')

    for (const option of options) {
      if (option.value === this.element.value) {
        return option
      }
    }
  }

  navigate() {
    const href = this.selectedOption.getAttribute('href')
    window.location.href = href
  }
}
