import SearchWithoutPagyController from './search_without_pagy_controller'
import toSnakeCase from "../helpers/toSnakeCase"

export default class extends SearchWithoutPagyController {
  static values = { applicantsPageParamName: String, accountsPageParamName: String, usersPageParamName: String }

  getExtraQueryParams() {
    return {
      [toSnakeCase(this.applicantsPageParamNameValue)]: null,
      [toSnakeCase(this.accountsPageParamNameValue)]: null,
      [toSnakeCase(this.usersPageParamNameValue)]: null
    }
  }
}
