import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import updateQuestionNumbers from '../AptitudeTests/updateQuestionNumbers'

export default class extends Controller {
  initialize(sortableOptions = this.defaultSortableOptions) {
    this.sortable = new Sortable(this.element, sortableOptions)
  }

  get defaultSortableOptions() {
    return {
      filter: '.not-sortable',
      handle: '.sort-handle',
      direction: 'vertical',
      group: {
        name: 'sortable_aptitude_questions',
        pull: true,
        // return true if we're allowed to put the
        // currently dragged element into this group.
        put: () => this.isQuestion(Sortable.dragged)
      },
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      onEnd: event => this.handleDragEnd(event)
    }
  }

  isQuestion(element) {
    return element.classList.contains('question')
  }

  // This happens after a user drops the question
  handleDragEnd(event) {
    updateQuestionNumbers()
  }
}
