import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ["questionNumberInput", "questionNumberDisplay"]

  initialize(sortableOptions = this.defaultSortableOptions) {
    this.sortable = new Sortable(this.element, sortableOptions)
  }

  get defaultSortableOptions() {
    return {
      filter: '.not-sortable',
      handle: '.sort-handle',
      direction: 'vertical',
      group: {
        name: 'sortable_job_specific_questions',
        pull: true,
        // return true if we're allowed to put the
        // currently dragged element into this group.
        put: () => this.isQuestion(Sortable.dragged)
      },
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      onEnd: event => this.handleDragEnd(event)
    }
  }

  isQuestion(element) {
    return element.classList.contains('question')
  }

  // This happens after a user drops the question
  handleDragEnd(event) {
    this.questionNumberInputTargets.forEach((question, index) => {
      const questionNumber = index + 1
      this.questionNumberDisplayTargets[index].innerHTML = questionNumber
      this.questionNumberInputTargets[index].value = questionNumber
    })
  }
}
