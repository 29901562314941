import "../stylesheets/controllers/toggle_hidden_controller.scss";
import StimulusReflexController from "./stimulus_reflex_controller";

export default class extends StimulusReflexController {
  static targets = ["toggleable"];

  toggle(event) {
    if (event) {
      event.preventDefault();
    }
    this.toggleableTargets.forEach(target => {
      this.toggleElement(target);
    });
  }

  toggleWithoutPreventDefault() {
    this.toggleableTargets.forEach(target => {
      this.toggleElement(target);
    });
  }

  async toggleElement(element) {
    if (element.hasAttribute("data-hidden")) {
      await this.showElement(element);
    } else {
      await this.hideElement(element);
    }
  }

  hideIfShown() {
    this.toggleableTargets.forEach(target => {
      if (target.hasAttribute("data-hidden")) {
        return;
      } else {
        this.hideElement(target);
      }
    });
  }

  showIfHidden() {
    this.toggleableTargets.forEach(target => {
      if (target.hasAttribute("data-hidden")) {
        this.showElement(target);
      }
    });
  }

  forceHide() {
    this.hideIfShown()
  }

  hide(event) {
    if (this.scope.containsElement(event.target)) return
    this.hideIfShown();
  }

  async beforeHide(element) {
    //
  }

  async afterHide(element) {
    //
  }

  async beforeShow(element) {
    //
  }

  async afterShow(element) {
    //
  }

  async showElement(element) {
    await this.beforeShow(element);
    element.removeAttribute("data-hidden");
    await this.afterShow(element);
  }

  async hideElement(element) {
    await this.beforeHide(element);
    element.setAttribute("data-hidden", "");
    await this.afterHide(element);
  }
}
