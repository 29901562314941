function isBlank(x) {
  if (x == null) {
    return true;
  }
  if (x.trim) {
    return x.trim() === "";
  }
  return false;
}

function queryParamsAreEmpty(queryParams) {
  return queryParams.toString() === "";
}

export function get(paramName) {
  return new URLSearchParams(window.location.search).get(paramName);
}

export function update(updates) {
  const queryParams = new URLSearchParams(window.location.search);

  for (const key in updates) {
    const value = updates[key];
    if (isBlank(value)) {
      queryParams.delete(key);
    } else {
      queryParams.set(key, value);
    }
  }

  if (queryParamsAreEmpty(queryParams)) {
    history.replaceState(null, null, window.location.pathname);
  } else {
    history.replaceState(null, null, "?" + queryParams.toString());
  }
}
