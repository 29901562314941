import StimulusReflexController from './stimulus_reflex_controller'
import toSnakeCase from '../helpers/toSnakeCase.js'

export default class extends StimulusReflexController {
	static targets = ['element', 'select']

	connect() {
		if (this.hasSelectTarget) {
			for (let element of this.elementTargets) {
				this.toggle(element, element.dataset.values, this.selectTarget.value)
			}
		}
		if (this.hasSelectTargets) {
			for (let select of this.selectTargets) {
				this.toggle(this.elementTarget, this.elementTarget.dataset.values, select.value)
			}
		}
	}

	selectChanged(event) {
		for (let element of this.elementTargets) {
			this.toggle(element, element.dataset.values, event.target.value)
		}
	}

	toggle(element, values, value) {
		if (element && values) {
			let hidden = true
			let disabled = hidden
			for (let _value of values.split(',')) {
				if (toSnakeCase(_value) === value) {
					hidden = false
					disabled = hidden
				}
			}
			element.hidden = hidden
		}
	}
}
