import sleep from '../helpers/sleep'
import TransitionHiddenController from './transition_hidden_controller'
import '../stylesheets/controllers/super_admin_mobile_sidenav_controller.scss'

export default class extends TransitionHiddenController {
  static targets = ['wrapper', 'menu', 'overlay']

  async show (event) {
    event.preventDefault()

    await this.showElement(this.wrapperTarget)
    this.showElement(this.menuTarget)
    this.showElement(this.overlayTarget)
  }

  async hide (event) {
    console.log('hiding')
    event.preventDefault()

    const hideMenuPromise = this.hideElement(this.menuTarget)
    const hideOverlayPromise = this.hideElement(this.overlayTarget)
    await Promise.all([hideMenuPromise, hideOverlayPromise])
    await this.hideElement(this.wrapperTarget)
  }

  get toggleableTargets () {
    return [this.wrapperTarget, this.menuTarget, this.overlayTarget]
  }
}
