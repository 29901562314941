if (window.$) {
  window.$.fn.extend({
    hasClasses: function (selectors) {
      const $self = $(this)
      return selectors.every(selector => $self.hasClass(selector))
    }
  })
}

export default window.$
