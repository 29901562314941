import { Controller } from "stimulus"

export default class extends Controller {
  handleNewQuestionClick () {
  }

  handlePrebuiltQuestionClick () {
  }

  connect () {
  }
}
