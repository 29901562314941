import ModalController from './modal_controller'
import * as QueryParams from '../helpers/query_params'
import sleep from '../helpers/sleep'
import toSnakeCase from '../helpers/toSnakeCase'

export default class extends ModalController {
	static targets = ['queryParam', 'submitSpinner', 'submitButtonText', 'hideWithoutSubmit', ...ModalController.targets]

	get updateParamsReflexAction() {
		return 'Params#update_params'
	}

	async hide(event) {
		if (this.isTransitioning) {
			return
		}
		const queryParam = toSnakeCase(event.target.getAttribute('data-query-param'))
		console.log(queryParam)

		QueryParams.update({ [`${queryParam}`]: '' })
		this.isTransitioning = true

		const hideBackgroundPromise = this.hideElement(this.backgroundTarget)
		const hideBodyPromise = this.hideElement(this.bodyTarget)
		await Promise.all([hideBackgroundPromise, hideBodyPromise])
		await this.hideElement(this.wrapperTarget)
		await sleep(200)

		// Allow the page to scroll again once closed
		document.body.classList.remove('overflow-hidden')

		if (QueryParams.get(queryParam)) await this.stimulateWhenConnected(this.updateParamsReflexAction, event.target, { [`${queryParam}`]: '' })

		this.isTransitioning = false
	}

	async submitAndHide(event) {
		event.preventDefault()

		if (this.isTransitioning) {
			return
		}

		const queryParam = toSnakeCase(event.target.getAttribute('data-query-param'))

		QueryParams.update({ [`${queryParam}`]: '' })
		this.isTransitioning = true

		this.showLoading()

		await this.stimulateWhenConnected('Dashboard#send_test_instructions', event.target, { serializeForm: true })
		document.body.classList.remove('overflow-hidden')

		if (QueryParams.get(queryParam)) await this.stimulateWhenConnected(this.updateParamsReflexAction, event.target, { [`${queryParam}`]: '' })

		this.isTransitioning = false
	}

	async showLoading() {
		await this.hideElement(this.submitButtonTextTarget)
		this.showElement(this.submitSpinnerTarget)
	}

	isUpdateParamsReflexAction(event) {
		return event.detail.reflex === this.updateParamsReflexAction
	}

	handleReflexAfter(event) {
		if (this.isUpdateParamsReflexAction(event)) {
			// Set isTransitioning to false whenever any updateParams reflex
			// completes. We do this because if the user is clicking rapidly,
			// they might trigger another updateParams reflex while we're waiting on
			// the current updateParams reflex to complete. If that happens, our
			// original updateParams reflex never resolves, meaning this.isTransitioning
			// never gets reset to false. By doing it here, we make sure that reset happens,
			// regardless of which controller instance triggered the reflex.
			this.isTransitioning = false
		}
	}

	connect() {
		super.connect()
		this.isTransitioning = false
	}
}
