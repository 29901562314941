import { Controller } from 'stimulus'
import 'focus-within-polyfill'

const browserSupportsFocusWithin = () => {
  try {
    document.querySelector(':focus-within')
    return true
  } catch (err) {
    return false
  }
}

export const SearchableMixin = superclass => 
  class SearchableController extends superclass {
    static targets = ['suggestion',
                      'query',
                      'suggestionsContainer',
                      'input']

    get showSuggestionsBeforeSearching () {
      return this.data.has('show-suggestions-before-searching') 
    }
    connect () {
      if (this.showSuggestionsBeforeSearching) {
        this.markElementsThatMatchQuery()
      }
    }

    getQuery () {
      if (!this._query) {
        this._query = ''
      }
      return this._query
    }

    set query (newQuery) {
      this._query = newQuery
    }

    handleQueryChange (event) {
      this.setQuery(event.target.value)
    }

    setQuery (newQuery) {
      this._query = newQuery
      this.markElementsThatMatchQuery()
    }

    matchesQuery (element) {
      const query = this.getQuery().toLowerCase()
      const searchField  = element.getAttribute('data-search-field').toLowerCase()
      // string.includes('') always returns true, by the way.
      return searchField.includes(query)
    }

    markElementsThatMatchQuery () {
      for (const suggestion of this.suggestionTargets) {
        if (this.matchesQuery(suggestion)) {
          suggestion.setAttribute('data-matches-query', true)
        } else {
          suggestion.removeAttribute('data-matches-query')
        }
      }
      this.showSuggestionsContainer()
    }

    clearInputs (event) {
      if (event) event.preventDefault()

      this.setQuery('')
      this.inputTargets.forEach(input => {
        input.value = ''
      })
      this.queryTarget.focus()

      if (!this.showSuggestionsBeforeSearching) {
        // Hide suggestions until the user types something.
        for (const suggestion of this.suggestionTargets) {
          suggestion.removeAttribute('data-matches-query')
        }
      }
    }

    showSuggestionsContainer () {
      const hasSuggestionsMatchingQuery = this.hasSuggestionsMatchingQuery()
      const hasFocusWithin = this.hasFocusWithin()
      if (hasSuggestionsMatchingQuery && hasFocusWithin) {
        this.element.setAttribute('data-show-suggestions-container', true)
      }
    }

    hideSuggestionsContainer () {
      this.element.removeAttribute('data-show-suggestions-container')
    }

    hasSuggestionsMatchingQuery () {
      return this.suggestionTargets.some(suggestion => suggestion.getAttribute('data-matches-query'))
    }

    hasFocusWithin () {
      if (browserSupportsFocusWithin()) {
        return !!this.element.querySelector(':focus-within')
      } else {
        return this.element.classList.contains('focus-within')
      }
    }
  }

export default SearchableMixin(Controller)
