import $ from '../jquery'
import fetchWithDefaults from '../helpers/fetchWithDefaults'

const updateQuestionNumbers = () => {
  const form = $('form.question-numbers-form')
  const formAction = `${form.attr('action')}.js`

  $('#questions-list').find('.question-number-input').each(function (idx) {
    const question_number = idx + 1
    $(this).siblings('.question-number-display').text(question_number)
    $(this).val(question_number)
  })

  return fetchWithDefaults(formAction, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: form.serialize()
  })
}

export default updateQuestionNumbers
