import sleep from '../helpers/sleep'

export default class {
  static async showWarning() {
    self.document.getElementById('action-cable-warning').removeAttribute("data-hidden")
    await sleep(10000)
    self.document.getElementById('action-cable-warning').setAttribute("data-hidden", "true")
 
  }
}
