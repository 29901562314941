import SearchWithoutPagyController from './search_without_pagy_controller'
import toSnakeCase from '../helpers/toSnakeCase'

export default class extends SearchWithoutPagyController {
  static values = { pageParamName: String }

  getExtraQueryParams() {
    return { 
      [toSnakeCase(this.pageParamNameValue)]: null,
      'sort': null
    }
  }

  get pageParamName() {
    return this.pageParamValue || 'page'
  }
}
