import StimulusReflexController from "./stimulus_reflex_controller";
import sleep from "../helpers/sleep";

export default class extends StimulusReflexController {
  static targets = ['fileInformationFeedback', 'submitButton', "removeButton", "selectFilesButton"]

  onFileUpload(event) {
    const uploadedFiles = document.getElementById("applicant_files").files
    if(uploadedFiles.length > 0) {
      // toggle the feedback target
      if(uploadedFiles.length == 1) {
        this.fileInformationFeedbackTarget.innerHTML = uploadedFiles[0].name
        this.submitButtonTarget.innerHTML = "Upload File"
      }
      else {
        this.fileInformationFeedbackTarget.innerHTML = uploadedFiles.length + " files selected"
        this.submitButtonTarget.innerHTML = "Upload Files"
      }

      // toggle the submit button target
      this.submitButtonTarget.removeAttribute("data-hidden")
    }
  }

  onSubmit(event) {
    this.submitButtonTarget.innerHTML = "Uploading..."
    this.submitButtonTarget.disabled = true
  }
  
  remove() {
    this.removeButtonTarget.disabled = true
    this.removeButtonTarget.innerHTML = "Removing..."
    this.removeButtonTarget.style.cursor = "auto"
  }

  async toggleFileSelectButton(event) {
    this.selectFilesButtonTarget.style.pointerEvents = "none"
    this.fileInformationFeedbackTarget.innerHTML = "Opening..."
    this.submitButtonTarget.setAttribute("data-hidden", "true")
    
    await sleep(1000)

    this.fileInformationFeedbackTarget.innerHTML = "Select files for upload"
    this.selectFilesButtonTarget.style.pointerEvents = "visible"
  }
}
