const getCsrfToken = () => {
  const csrfMetaTag = document.head.querySelector('meta[name=csrf-token]')
  if (csrfMetaTag) {
    return csrfMetaTag.getAttribute('content')
  } else {
    return ""
  }
}

const applyDefaults = options => {
  const optionsWithDefaults = { ...options }

  const defaultHeaders = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': getCsrfToken(),
    'X-Requested-With': 'XMLHttpRequest', // so that request.xhr? returns true in Rails
    'Accept': '*/*'
  }
  optionsWithDefaults.headers = {
    ...defaultHeaders,
    ...options.headers
  }

  optionsWithDefaults.credentials = options.credentials || 'same-origin'
  optionsWithDefaults.mode = options.mode || 'cors'

  return optionsWithDefaults
}

export default (url, options) => {
  const optionsWithDefaults = applyDefaults(options)
  return window.fetch(url, optionsWithDefaults)
}

