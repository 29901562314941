import { Controller } from 'stimulus'
import '../stylesheets/controllers/clone_template.scss'

const insertBefore = (existingNode, newNode) => {
  existingNode.parentNode.insertBefore(newNode, existingNode)
}

const deepClone = element => element.cloneNode(true)

export default class extends Controller {
  static targets = ['template', 'insertClonesBeforeThis']
  connect() {
    this.cachedTemplate = deepClone(this.templateTarget)
  }
  clone() {
    const insertLocation = this.insertClonesBeforeThisTarget
    const clone = deepClone(this.cachedTemplate)

    insertBefore(insertLocation, clone)
  }
}
