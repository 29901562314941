import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['previewIFrame', 'input']

  copyPersonalNoteIntoPreview () {
    const value = this.inputTarget.value
    if (value) {
      this.previewIFrameTarget.contentDocument.querySelector('#personal-note-preview').innerHTML = value
    }
  }
}
