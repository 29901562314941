import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import awaitEvent from '../helpers/awaitEvent'
import isOnline from '../helpers/is_online'
import fetchWithDefaults from '../helpers/fetchWithDefaults'
import * as Sentry from '@sentry/browser'
import ActionCableWarningController from '../../javascript/controllers/action_cable_warning_controller'

let actionCableSupportedHasBeenSet = false
export default class extends Controller {
  connect() {
    super.connect()
    StimulusReflex.register(this)
    this.setActionCableSupported()
  }

  async actionCableConnectionOpened() {
    // This method gets added when we call StimulusReflex.register(this) in our connect method
    if (this.isActionCableConnectionOpen()) {
      return
    }

    try {
      await awaitEvent(document, 'stimulus-reflex:connected', 10000)
      // We can uncomment this to test out our action cable warning since we can't reproduce it locally
      // ActionCableWarningController.showWarning()
    } catch (error) {
      ActionCableWarningController.showWarning()
      throw new Error ("Couldn't make an Action Cable connection. This is probably resulting in an annoying error for this user.")
    }
  }

  async setActionCableSupported() {
    if (actionCableSupportedHasBeenSet || !window.signedIn) {
      return
    }

    actionCableSupportedHasBeenSet = true
    let actionCableSupported = true
    try {
      await this.actionCableConnectionOpened()
    } catch (error) {
      actionCableSupported = false
    }

    await fetchWithDefaults("/users/set_action_cable_supported", {
      method: 'POST',
      body: JSON.stringify({
        action_cable_supported: actionCableSupported
      })
    })
  }

  async stimulateWhenConnected(...args) {
    try {
      await this.actionCableConnectionOpened()
      const stimulateResult = await this.stimulate(...args)
      return stimulateResult
    } catch (error) {
      const userIsOnline = await isOnline()
      if (userIsOnline) {
        console.log(error)
        Sentry.captureException(error)
      } else {
        console.log("It looks like you're not online. Check your connection?")
        console.log(error)
      }
    }
  }

  /* Application wide lifecycle methods.
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#demo"
   *
   *   error - error message from the server
   */

  beforeReflex(element, reflex) {
    // document.body.classList.add('wait')
  }

  reflexSuccess(element, reflex, error) {
    // show success message etc...
  }

  reflexError(element, reflex, error) {
    console.log(error)
    // show error message etc...
  }

  afterReflex(element, reflex) {
    // document.body.classList.remove('wait')
  }
}
