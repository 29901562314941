import TransitionHiddenController from './transition_hidden_controller'
import '../stylesheets/controllers/flash_message_controller.scss'

export default class extends TransitionHiddenController {
  get toggleableTargets() {
    return [this.element]
  }

  cancelHide() {
    window.clearTimeout(this.timeoutId)
  }

  hideAfterDelay() {
    this.timeoutId = window.setTimeout(() => {
      super.hideIfShown()
    }, 3000)
  }

  async connect() {
    super.connect()

    this.showIfHidden()
    this.hideAfterDelay()
  }
}
