import Flatpickr from "stimulus-flatpickr";

export default class extends Flatpickr {
  static targets = ["instance"];

  initialize() {
    this.config = {
      dateFormat: "m-d-Y",
      minDate: Date.now(),
      static: true
    };
  }

  clearDate() {
    this.fp.clear();
  }
}
