import ToggleHiddenController from './toggle_hidden_controller'

export default class extends ToggleHiddenController {
  static targets = ['toggleable']

  getId(element) {
    return element.getAttribute("data-id")
  }

  toggleTargetWithId(id) {
    this.toggleableTargets.forEach(target => {
      if (this.getId(target) === id) {
        this.toggleElement(target)
      }
    })
  }

  toggle (event) {
    const id = this.getId(event.target)
    this.toggleTargetWithId(id)
  }
}
