import KeyboardSelectableController from './keyboard_selectable_controller'
import '../stylesheets/controllers/find_or_new_controller.scss'

const isEmpty = string => string.trim() === ''

export default class extends KeyboardSelectableController {
  static targets = [
    ...KeyboardSelectableController.targets,
    'newPlaceholder',
    'newPlaceholderText',
    'selectedNewPlaceholder',
    'selectedNewPlaceholderText',
    'newRecordForm'
  ]

  focusNextInput () {
    let previousInputWasFocused = false
    for (const input of this.inputTargets) {
      if (previousInputWasFocused) {
        setTimeout(() => input.focus(), 50)
        break
      }

      previousInputWasFocused = document.activeElement === input
    }
  }

  setQuery (newQuery) {
    super.setQuery(newQuery)
    this.updateNewPlaceholder(newQuery)
    this.updateSelectedNewPlaceholder(newQuery)
  }

  updateNewPlaceholder (newQuery) {
    if (!this.hasNewPlaceholderTarget) return

    const newQueryIsEmpty = isEmpty(newQuery)
    if (newQueryIsEmpty) {
      this.newPlaceholderTarget.removeAttribute('data-matches-query')
      this.newPlaceholderTarget.setAttribute('data-empty', true)
    } else {
      this.newPlaceholderTarget.removeAttribute('data-empty')
      this.newPlaceholderTarget.setAttribute('data-matches-query', true)
      this.newPlaceholderTextTarget.textContent = `Add ${newQuery}`
    }
  }

  updateSelectedNewPlaceholder (newQuery) {
    this.selectedNewPlaceholderTextTarget.textContent = newQuery 
  }

  selectHighlightedIfShowingSuggestions () {
    if (!this.highlightedOption) return

    const highlightedOptionIsNewPlaceholder = this.highlightedOption
      .getAttribute('data-find-or-new-target')
      .includes('newPlaceholder')
    if (highlightedOptionIsNewPlaceholder) {
      this.selectNewPlaceholder()
    } else {
      super.selectHighlightedIfShowingSuggestions()
    }
  }

  selectNewPlaceholder () {
    this.focusNextInput()
    this.selectedNewPlaceholderTarget.removeAttribute('data-hide')
    this.inputTarget.setAttribute('data-hide', true)
  }

  clearSelectedNewPlaceholder () {
    this.selectedNewPlaceholderTarget.setAttribute('data-hide', true)
    this.inputTarget.removeAttribute('data-hide')
    this.clearInputs()
  }

  hasSuggestionsMatchingQuery () {
    const queryIsEmpty = isEmpty(this.getQuery())

    // If the query is empty, see if there are any matching suggestions from existing records.
    if (queryIsEmpty) {
      return this.suggestionTargets.some(suggestion =>
        suggestion.getAttribute('data-matches-query')
      )
    } else {
      // If the query isn't empty, return true because the new placeholder is a matching suggestion.
      return true
    }
  }
}
