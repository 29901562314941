import ToggleHiddenController from './toggle_hidden_controller'
import isOnline from '../helpers/is_online'

export default class extends ToggleHiddenController {
  get toggleableTargets () {
    return [this.element]
  }

  async warnIfOffline () {
    // console.log('Checking if online')
    const userIsOnline = await isOnline()
    if (userIsOnline) {
      this.hideWarning()
    } else {
      this.showWarning()
    }
  }

  hideWarning () {
    this.hideIfShown()
  }

  showWarning () {
    this.showIfHidden()
  }

  connect () {
    super.connect()

    const warnIfOffline = () => this.warnIfOffline()

    window.setInterval(warnIfOffline, 3000)
  }
}