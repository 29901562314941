import TrixWarning from './TrixWarning'
import LinterController from './linter_controller'

export default class TrixLinterController extends LinterController {
  getText() {
    return this.document.toString()
  }
  get document() {
    return this.editor.getDocument()
  }
  get editor() {
    return this.element.editor
  }
  createWarning(error) {
    return TrixWarning.createAndConnectToDom(error, this.editor)
  }
  connect() {
  }
}

document.addEventListener('trix-initialize', event => {
  let urlField = event.target.toolbarElement.querySelector('input[name="href"]')
  urlField.type = 'text';
  urlField.pattern = "(\\{\\{.+\\}\\})|(.+\\.[A-Za-z0-9]+.*)";
})