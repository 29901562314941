import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "hiddenFileInput",
    "beforeUploadContainer",
    "afterUploadContainer",
    "afterUploadFileName",
    "dropArea",
    "resumeWasUploaded",
    "requiredFileWasUploaded"
  ]

  clickHiddenFileInput() {
    this.hiddenFileInputTarget.click()
  }

  onFileSelection() {
    this.beforeUploadContainerTarget.setAttribute('data-hidden', "")
    this.afterUploadContainerTarget.removeAttribute("data-hidden")

    // If multiple files are allowed get all the names to show after upload. Otherwise just show the name of the first file.
    const uploadedFiles = this.hiddenFileInputTarget.files
    if (uploadedFiles.length > 1) {
      const fileNames = Object.entries(uploadedFiles).map((file) => {
        return " " + file[1].name
      })
      this.afterUploadFileNameTarget.innerHTML = fileNames
    } else {
      this.afterUploadFileNameTarget.innerHTML = uploadedFiles[0].name
    }

    // Validations. Here's where we could add some logic to validate multiple required files.
    const isResume = this.hasResumeWasUploadedTarget
    if (isResume) {
      console.log("hi")
      this.resumeWasUploadedTarget.value = true
    } else { // Is file request
      const is_required = this.requiredFileWasUploadedTarget.hasAttribute('data-required')
      // Not sure why we have to use null istead of false here 🤷‍♂️
      this.requiredFileWasUploadedTarget.value = is_required ? true : null
    }
  }

  removeFile() {
    this.hiddenFileInputTarget.value = null

    this.afterUploadContainerTarget.setAttribute('data-hidden', "")
    this.beforeUploadContainerTarget.removeAttribute("data-hidden")

    if (this.hasResumeWasUploadedTarget) {
      this.resumeWasUploadedTarget.value = null
    } else {
      this.requiredFileWasUploadedTarget.value = null
    }
  }

  onDragOver(event) {
    event.preventDefault()
    event.dataTransfer.effectAllowed = "none"
    event.dataTransfer.dropEffect = 'copy'
  }

  onDrop(event) {
    event.preventDefault()
    event.dataTransfer.effectAllowed = "none"
    event.dataTransfer.dropEffect = "copy"

    this.beforeUploadContainerTarget.setAttribute('data-hidden', "")
    this.afterUploadContainerTarget.removeAttribute("data-hidden")

    // If multiple files are allowed get all the names to show after upload. Otherwise just show the name of the first file.
    const uploadedFiles = event.dataTransfer.files
    if (uploadedFiles.length > 1) {
      const fileNames = Object.entries(uploadedFiles).map((file) => {
        return " " + file[1].name
      })
      this.afterUploadFileNameTarget.innerHTML = fileNames
    } else {
      this.afterUploadFileNameTarget.innerHTML = uploadedFiles[0].name
    }

    // Repopulate the hidden input since our page refresh removes that data
    this.hiddenFileInputTarget.files = uploadedFiles

    // Validations. We could implement some logic to validate multiple files here if we want.
    const isResume = this.hasResumeWasUploadedTarget
    if (isResume) {
      this.resumeWasUploadedTarget.value = true
    } else { // Is file request
      const is_required = this.requiredFileWasUploadedTarget.hasAttribute('data-required')
      // Not sure why we have to use null istead of false here 🤷‍♂️
      this.requiredFileWasUploadedTarget.value = is_required ? true : null
    }
  }
}