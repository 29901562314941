export class AwaitEventTimedOutError extends Error {}

function listenOnce (element, eventType, callback) {
  function runOnce (event) {
    callback(event)
    removeEventListener()
  }

  function removeEventListener () {
    element.removeEventListener(eventType, runOnce)
  }

  element.addEventListener(eventType, runOnce)

  return removeEventListener
}

export default function awaitEvent (element, eventType, timeout = 5000) {
  return new Promise((resolve, reject) => {
    const removeEventListener = listenOnce(element, eventType, resolve)

    function rejectBecauseTimeoutHasPassed () {
      removeEventListener()

      const errorMessage = `Expected a ${eventType} event to happen within ${timeout} milliseconds, but it didn't.`
      const error = new AwaitEventTimedOutError(errorMessage)
      reject(error)
    }

    window.setTimeout(rejectBecauseTimeoutHasPassed, timeout)
  })
}
