import debounce from 'lodash.debounce'
import ToggleHiddenController from './toggle_hidden_controller'
import * as QueryParams from '../helpers/query_params'

export default class extends ToggleHiddenController {
  static targets = ['queryInput']

  get queryParamName() {
    return this.queryInputTarget.getAttribute('name')
  }

  setQuery() {
    this.showSpinner()

    const extraQueryParams = this.getExtraQueryParams()
    QueryParams.update({
      [this.queryParamName]: this.queryInputTarget.value,
      ...extraQueryParams
    })

    this.debouncedStimulateWhenConnected()
  }

  getExtraQueryParams() {
    return {}
  }

  showSpinner() {
    if (!this.loading) {
      // This also hides the clear query button
      // if it's showing.
      this.toggle()
      this.loading = true
    }
  }

  afterReflex() {
    this.loading = false
  }

  clearQuery(event) {
    event.preventDefault()
    this.showSpinner()

    const extraQueryParams = this.getExtraQueryParams()
    QueryParams.update({
      [this.queryParamName]: null,
      page: null,
      global_search_employees_page: null,
      global_search_applicants_page: null,
      global_search_campaigns_page: null,
      ...extraQueryParams
    })

    this.queryInputTarget.value = ''
    this.stimulateWhenConnected()
  }

  focusQueryInput() {
    this.queryInputTarget.focus()
  }

  preventDefault(event) {
    event.preventDefault()
  }

  initialize() {
    // this.setQuery = debounce(this.setQuery.bind(this), 500)
    this.debouncedStimulateWhenConnected = debounce(this.stimulateWhenConnected.bind(this), 500)
  }
}
