import ToggleHiddenController from './toggle_hidden_controller'
import StimulusReflex from '../initializers/stimulus_reflex'
import * as QueryParams from '../helpers/query_params'

export default class extends ToggleHiddenController {
  get updateParamsReflexAction() {
    return 'Params#update_params'
  }

  getParamUpdates(event) {
    event.preventDefault()
    const element = event.currentTarget

    if (element.tagName === "SELECT") {
      return JSON.parse(element.value)
    } else {
      const updatesString = element.getAttribute("data-param-updates")
      return JSON.parse(updatesString)
    }
  }

  toggleSpinner() {
    this.toggle()
  }

  async setScope(event) {
    if (this.isTransitioning) {
      return
    }

    const updates = this.getParamUpdates(event)
    QueryParams.update(updates)

    this.isTransitioning = true
    this.toggleSpinner()

    // If another updateParams reflex from another controller instance
    // gets triggered while we're waiting on this one, this one will never
    // resolve.
    await this.stimulateWhenConnected(this.updateParamsReflexAction, this.element, updates)
    this.isTransitioning = false
  }

  isUpdateParamsReflexAction(event) {
    return event.detail.reflex === this.updateParamsReflexAction
  }

  handleReflexAfter(event) {
    if (this.isUpdateParamsReflexAction(event)) {
      // Set isTransitioning to false whenever any updateParams reflex
      // completes. We do this because if the user is clicking rapidly,
      // they might trigger another updateParams reflex while we're waiting on
      // the current updateParams reflex to complete. If that happens, our
      // original updateParams reflex never resolves, meaning this.isTransitioning
      // never gets reset to false. By doing it here, we make sure that reset happens,
      // regardless of which controller instance triggered the reflex.
      this.isTransitioning = false
    }
  }

  connect() {
    super.connect()
    this.isTransitioning = false
  }
}