import { Controller } from "stimulus"

let clicked = false

export default class extends Controller {
  preventDoubleClickOnSubmitButton(event) {
    if (clicked) {
      event.preventDefault()
      event.target.disabled = true
    }
    clicked = true
  }
}
