import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = ['report', 'toggleAllForTest', 'toggleAllForCampaign', 'toggleOnlyThis', 'submitButton']

	allReportTargets() {
		return Array.from(this.reportTargets)
	}

	reportTargetsForTest(test) {
		return this.allReportTargets().filter(reportTarget => reportTarget.dataset.test == test)
	}

	parentCheckbox(childCheckbox) {
		return Array.from(this.toggleAllForTestTargets).find(target => target.dataset.test == childCheckbox.dataset.test)
	}

	allCheckboxes() {
		return [...this.allReportTargets(), ...Array.from(this.toggleAllForTestTargets), this.toggleAllForCampaignTarget]
	}

	// Actions

	toggleOnlyThis(event) {
		// Uncheck all
		this.allCheckboxes().forEach(checkbox => (checkbox.checked = false))
    
		// Then toggle the first reportTarget in the DOM
		const firstReport = this.allReportTargets()[0]
		if (!firstReport.disabled) firstReport.checked = event.target.checked
		this.validateForm()
	}

	toggleAllForCampaign(event) {
		this.toggleOnlyThisTarget.checked = false
		this.allCheckboxes().forEach(reportTarget => {
			if (!reportTarget.disabled) reportTarget.checked = event.target.checked
		})
		this.validateForm()	
	}

	toggleAllForTest(event) {
		this.reportTargetsForTest(event.target.dataset.test).forEach(report => (report.checked = event.target.checked))
	}

	checkSelected(event) {
		// Check for all of this test
		const allForTestChecked = this.reportTargetsForTest(event.target.dataset.test).every(checkbox => checkbox.checked)
		this.parentCheckbox(event.target).checked = allForTestChecked
		
		// check for all
		const allChecked = this.allReportTargets().every(checkbox => checkbox.checked)
		this.toggleAllForCampaignTarget.checked = allChecked
		
		// uncheck the only this
		this.toggleOnlyThisTarget.checked = false

		// check toggleOnlyThisTarget if only the first report is checked
		this.checkToggleOnlyThisTargetIfFirstReportIsChecked()

		this.validateForm()	
	}

	validateForm() {
		const disabled = !this.anyTargetsAreChecked()
		this.submitButtonTarget.disabled = disabled	
	}

	anyTargetsAreChecked() {
		return this.allReportTargets().some(target => target.checked)
	}

	checkToggleAllForTestTargetIfAllForTestCheckedByDefault() {
		this.toggleAllForTestTargets.forEach(toggleAllForTestTarget => {
			const allReportsForTestChecked = this.reportTargetsForTest(toggleAllForTestTarget.dataset.test).every(checkbox => checkbox.checked)	
			if (allReportsForTestChecked) {
				toggleAllForTestTarget.checked = true
			}
		})
	}

	checkToggleAllForCampaignTargetIfAllForCampaignCheckedByDefault() {
		
		if (this.allReportTargets().every(checkbox => checkbox.checked)) {
			this.toggleAllForCampaignTarget.checked = true
		}
		this.validateForm()	
	}

	checkToggleOnlyThisTargetIfFirstReportIsChecked() {
		const firstReport = this.allReportTargets()[0]
		let checkedReports = 0
		this.allReportTargets().forEach(target => {	
			if (target.checked === true) {
				checkedReports++
			}
		})
		if (firstReport.checked === true && checkedReports === 1) {
			this.toggleOnlyThisTarget.checked = true
		}
	}

	connect() {
		// We need these functions to check the appropriate parent checkboxes when we show the modal 
		// in order to update an InterviewPacket. All checkboxes default to being checked if we show the modal for printing or sharing.
		this.checkToggleAllForTestTargetIfAllForTestCheckedByDefault()
		this.checkToggleAllForCampaignTargetIfAllForCampaignCheckedByDefault()
		this.checkToggleOnlyThisTargetIfFirstReportIsChecked()
		this.validateForm()	
	}
}
