import Warning from './Warning'
import getTextBoundingBox from '../../helpers/getTextBoundingBox'

export default class InputWarning extends Warning {
  static createAndConnectToDom(error, input) {
    const warning = new InputWarning(error, input)
    warning.connectToDom()
    return warning
  }
  constructor(error, input) {
    super(error)
    this.input = input
  }
  getBoundingBoxAtIndex(index) {
    return getTextBoundingBox(this.input, index, index + 1)
  }
}