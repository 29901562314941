import { createConsumer, logger } from '@rails/actioncable'
import * as Sentry from '@sentry/browser'

logger.enabled = true

function isConsumer (object) {
  if (object) {
    try {
      return (
        object.constructor.name === 'Consumer' &&
        object.connect &&
        object.disconnect &&
        object.send
      )
    } catch (e) {}
  }
  return false
}

function findConsumer (object, depth = 0) {
  if (!object) return null
  if (depth > 3) return null
  if (isConsumer(object)) return object
  return Object.values(object)
    .map(o => findConsumer(o, depth + 1))
    .find(o => o)
}

export function getConsumer () {
  return findConsumer(window) || createConsumer()
}

const consumer = getConsumer()

// const ws = new WebSocket('ws://localhost:3000/cablez')
// const ws = new WebSocket('wss://staging-heroku.hiresuccess.com/cable')
// const ws = new WebSocket('wss://heroku.hiresuccess.com/cable')
// ws.onerror = function (error) {
//   console.log("error 🎉:", error);
//   // Sentry.captureException(error)
// }

export default consumer
