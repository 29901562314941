import { Controller } from 'stimulus'

export default class extends Controller {
  get successMessage () {
    return this.element.getAttribute('data-success-message') || 'Success!'
  }

  get href () {
    return this.element.getAttribute('href')
  }

  delete (event) {
    event.preventDefault()

    const target = event.target

    const confirmMessage = target.getAttribute('data-confirm-message') || 'Are you sure?'
    if (!confirm(confirmMessage)) return

    const href = target.getAttribute('href')

    const previousDisplay = this.element.style.display
    $(this.element).fadeOut('slow', () => {
      $.ajax(href, {
        type: 'DELETE',
        fail: () => this.element.style.display = previousDisplay
      })
    })
  }
}