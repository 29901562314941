import { Controller } from 'stimulus'

export const SelectableMixin = superclass =>
	class SelectableController extends superclass {
		static targets = ['option', 'optionsContainer']

		getIdFromEvent(event) {
			const option = `[data-${this.identifier}-target~="option"]`
			return event.currentTarget.closest(option).getAttribute('data-id')
		}

		getOptionWithId(id) {
			const matchesId = element => element.getAttribute('data-id') === id
			return this.optionTargets.find(matchesId)
		}

		select(event) {
			event.preventDefault()
			const id = this.getIdFromEvent(event)
			this.selectOptionWithId(id)
		}

		deselect(event) {
			event.preventDefault()
			const id = this.getIdFromEvent(event)
			this.deselectOptionWithId(id)
		}

		selectOptionWithId(id) {
			const option = this.getOptionWithId(id)
			option.setAttribute('data-selected', 'true')
			option.setAttribute('data-hide', '')
			option.dispatchEvent(new Event('option-selected'))

			this.toggleNewRecordFormHidden()
		}

		deselectOptionWithId(id) {
			const option = this.getOptionWithId(id)
			option.removeAttribute('data-selected')

			this.toggleNewRecordFormHidden()
		}

		toggleNewRecordFormHidden() {
			if (!this.data.has('singleRecord')) return

			if (this.newRecordFormTarget.hasAttribute('data-hidden')) {
				this.showNewRecordForm()
			} else {
				this.hideNewRecordForm()
			}
		}
		
		showNewRecordForm() {
			this.newRecordFormTarget.removeAttribute('data-hidden')
		}
		
		hideNewRecordForm() {
			this.newRecordFormTarget.setAttribute('data-hidden', '')
		}
	}

export default SelectableMixin(Controller)
